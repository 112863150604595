import React from 'react';
import logo from './logo.svg';
import logoTexto from './logo-agro-inova-texto.svg'
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


function App() {

  const handleWhatsApp = () => {
    window.open('https://api.whatsapp.com/send?phone=+5562985529887&text=ola+vim+pelo+site+estou+querendo+mais+informações+sobre&oq=ola+vim+pelo+site+estou+querendo+mais+informações+sobre', '_blank');
  };

  return (
    <div className="App">
      <header className="App-header">
        <div style={{ marginTop: '50px', textAlign: 'center' }}>
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <div>
          <img src={logoTexto} alt="logo" style={{ width: '100%' }} />
        </div>
        <div>
          <h1>Site em construção</h1>
          <h2>Mais informações entre em contato</h2>
        </div>
        <button className="App-button" onClick={handleWhatsApp}>
          <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: '8px' }} />
          WhatsApp
        </button>
      </header>
    </div>
  );
}

export default App;
